<template>
  <div class="container py-12">
    <!-- action bar -->
    <div class="fixed right-0 top-[4rem] md:top-[4rem]">
      <div
        class="bg-primary p-4 md:p-2 md:text-xs rounded-l rounded-sm text-white cursor-pointer"
        @click="cover.toggle"
      >
        封<br />面
      </div>
    </div>
    <div class="fixed right-0 top-[10rem] md:top-[14rem]">
      <div
        class="bg-primary p-4 md:p-2 md:text-xs rounded-l rounded-sm text-white cursor-pointer"
        @click="catalog.toggle"
      >
        筆<br />記<br />目<br />錄
      </div>
    </div>

    <el-dialog v-model="cover.show" width="300px">
      <div class="w-full"><Card :row="cover.row" :preview="true"></Card></div>
    </el-dialog>
    <el-drawer
      v-model="catalog.show"
      :with-header="false"
      :size="isMobile ? '80%' : '30%'"
      @opened="expand"
    >
      <div class="h-full w-full flex flex-col">
        <div class="flex flex-none">
          <div
            class="inline-block ml-auto cursor-pointer"
            @click="catalog.toggle"
          >
            <font-awesome-icon :icon="['fas', 'times']" />
          </div>
        </div>
        <el-scrollbar class="flex-grow h-full">
          <CatalogTree ref="treeRef" />
        </el-scrollbar>
      </div>
    </el-drawer>
    <div class="text-4xl text-primary mt-8">{{ note.row.title }}</div>
    <div v-if="!note.row.all" class="text-center">目前沒有內容</div>
    <div v-else v-loading="loading" class="content mt-12">
      <h2
        class="text-2xl font-bold py-2 px-4 text-primary bg-background-2 rounded"
      >
        {{ note.row.part }}
      </h2>
      <p class="mt-4 text-xl font-bold pl-4 text-primary-2">
        {{ note.row.chapter }}
      </p>
      <p class="mt-4 text-lg font-bold pl-4 text-primary-2">
        {{ note.row.section }}
      </p>
      <div
        class="mt-4"
        v-html="isMobile ? note.row.mobile : note.row.web"
      ></div>
    </div>

    <div v-if="note.row.filterIds?.length" class="mt-12">
      <div class="text-xl">相關試題</div>
      <div class="mt-8">
        <a
          v-for="filterItem in note.row.filterIds"
          :key="filterItem.id"
          href="#"
          class="text-lg py-3 mb-4 mr-4 px-6 rounded-full shadow duration-300 hover:shadow-lg text-primary bg-white"
          @click.prevent="
            $router.push({
              name: filterItem.type === 'sbj' ? 'Exam.Question' : 'Exam.List',
              params: { slug: filterItem.type },
              query: {
                type: filterItem.type,
                pg: 1,
                filterId: filterItem.id,
              },
            })
          "
        >
          {{ filterItem.name }}
        </a>
      </div>
    </div>

    <Pagination
      v-if="note.row.all > 0"
      :total="note.row.all"
      :current-page="currentPage || 1"
      class="mt-8"
      @page-changed="_updatePg"
    ></Pagination>
  </div>
</template>

<script setup>
import { ElDrawer, ElDialog, ElScrollbar } from 'element-plus';

import Pagination from '@/components/Pagination.vue';
import { provide, inject, computed, reactive, ref, onMounted } from 'vue';
import useNote from '@/modules/note/useNote';
import useCatalog from '@/modules/note/useCatalog';
import useCover from '@/modules/note/useCover';
import Card from '@/modules/note/_Card.vue';
import { useRoute, useRouter } from 'vue-router';
import Note from '@/api/Note';
import CatalogTree from '@/modules/note/Catalog.vue';

const route = useRoute();
const router = useRouter();
const isMobile = inject('isMobile');

const getToken = () => {
  return Note.token({
    noteKey: 'N100001',
  }).then((res) => {
    console.log(res);
    return res;
  });
};
if (!route.query.token) {
  getToken().then((res) => {
    router.push({
      name: 'note.preview',
      query: {
        pg: 1,
        token: res.data,
        id: route.params.id,
      },
    });
  });
}
// ;
const note = useNote();
const { loading } = note;

provide('note', note);
const cover = reactive({
  ...useCover(),
  show: false,
  toggle: () => {
    cover.show = !cover.show;
  },
});

const catalog = reactive({
  ...useCatalog(note.row),
  show: false,
  toggle: () => {
    catalog.show = !catalog.show;
  },
});
provide('catalog', catalog);
const treeRef = ref(null);
const expand = () => {
  treeRef.value.expand();
};
const _updatePg = (pg) => {
  if (!pg || currentPage.value === pg) return;
  router.push({ query: { pg: pg, token: route.query.token } });
  setTimeout(() => {
    note.preview();
  }, 0);
};

provide('updatePg', _updatePg);
const currentPage = computed(() => {
  return Number(route.query.pg);
});

onMounted(() => {
  cover.fetcher();
  note.preview();
  catalog.preview();
});
</script>
<style lang="sass" scoped>

:deep(.el-dialog)
  @apply bg-transparent border-0 shadow-none

:deep(.el-tree)
  .el-tree-node
    @apply mt-1
  >.el-tree-node
    @apply mt-6
</style>
<style lang="scss" scoped>
:deep(.el-tree) {
  .arrow {
    @apply relative;
    &:before {
      content: '→';
      @apply absolute top-0 left-[-18px] text-red-600 font-bold;
    }
  }
}
</style>
