import { reactive, ref } from 'vue';
import Note from '@/api/Note';

import { useRoute, useRouter } from 'vue-router';

export default () => {
  const row = reactive({});
  const route = useRoute();
  const router = useRouter();
  const loading = ref(false);

  const fetcher = () => {
    if (!route.query.token) return;
    loading.value = true;

    return Note.coverInfo({
      noteId: route.params.id,
      token: route.query.token,
    }).then((res) => {
      if (res.code === 1) {
        Object.assign(row, res.data);
      }

      loading.value = false;
    });
  };

  return { row, fetcher };
};
